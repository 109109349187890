var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kiosk-chart"
  }, [_vm.loading || _vm.options.series.length === 0 ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._m(0)]) : _c('Chart', {
    key: _vm.options.series.length,
    attrs: {
      "options": _vm.mergedOptions
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading graph...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading graph...")])]);
}]

export { render, staticRenderFns }