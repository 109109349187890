var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row mb-6"
  }, [_c('div', {
    staticClass: "col-4 text-center"
  }, [_c('div', {
    staticClass: "kiosk-card"
  }, [_vm._m(0), _c('GaugeChart', {
    staticClass: "kiosk-pie kiosk-benchmark",
    attrs: {
      "options": _vm.reebChartOptions
    }
  }), _vm._m(1), _vm._m(2), _c('p', {
    staticClass: "kiosk-card--display font-size-h1"
  }, [_vm._v(" This Building: "), _c('strong', [_vm._v(_vm._s(_vm.consumptionPerM2) + " kWh/m"), _c('sup', [_vm._v("2")])])])], 1)]), _vm._m(3), _vm._m(4)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("REEB Energy Benchmark"), _c('br'), _c('small', [_vm._v("Category: Office (Air-conditioned)")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "kiosk-card--display font-size-h2"
  }, [_c('i', {
    staticClass: "fa fa-circle text-success"
  }), _vm._v(" Best Practice: "), _c('strong', [_vm._v("124 kWh/m"), _c('sup', [_vm._v("2")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "kiosk-card--display font-size-h2 border-bottom pb-5 mb-5"
  }, [_c('i', {
    staticClass: "fa fa-circle text-warning"
  }), _vm._v(" Typical Practice: "), _c('strong', [_vm._v("174 kWh/m"), _c('sup', [_vm._v("2")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-4 text-center"
  }, [_c('div', {
    staticClass: "kiosk-card"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("LETI Energy Benchmark")]), _c('p', {
    staticClass: "kiosk-card--display"
  }, [_vm._v("TBC")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-4 text-center"
  }, [_c('div', {
    staticClass: "kiosk-card"
  }, [_c('h2', {
    staticClass: "kiosk-card--title"
  }, [_vm._v("Etainabl Energy Benchmark")]), _c('p', {
    staticClass: "kiosk-card--display"
  }, [_vm._v("TBC")])])]);
}]

export { render, staticRenderFns }