<template>
  <div class="kiosk-chart">
    <div v-if="loading || options.series.length === 0" class="d-flex justify-content-center">
      <div class="text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading graph...</span>
        </div>
        <div class="font-w600">Loading graph...</div>
      </div>
    </div>
    <Chart v-else :key="options.series.length" :options="mergedOptions" />
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidgaugeInit from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
solidgaugeInit(Highcharts);

export default {
  name: 'GaugeChart',
  components: {
    Chart
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({
        series: []
      })
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      defaultChartOptions: {
        chart: {
          type: 'solidgauge'
        },
        title: {
          text: ''
        },
        yAxis: {
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          labels: {
            y: 30
          }
        },
        pane: {
          center: ['50%', '65%'],
          size: '120%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: 'rgba(255,255,255,0.2)',
            innerRadius: '60%',
            outerRadius: '100%',
            borderWidth: 0,
            shape: 'arc'
          }
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 20,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        colors: ['#2f8491', '#3a7973', '#ffd15e'],
        series: [],
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        }
      }
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultChartOptions,
        ...this.options,
        chart: { ...this.defaultChartOptions.chart, ...(this.options.chart || {}) },
        tooltip: { ...this.defaultChartOptions.tooltip, ...(this.options.tooltip || {}) },
        yAxis: { ...this.defaultChartOptions.yAxis, ...(this.options.yAxis || {}) },
        plotOptions: {
          ...this.defaultChartOptions.plotOptions,
          ...(this.options.plotOptions || {}),
          solidguage: { ...this.defaultChartOptions.plotOptions.pie, ...(this.options.plotOptions.pie || {}) }
        }
      };
    }
  }
};
</script>
