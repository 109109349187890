<template>
  <div>
    <div class="row mb-6">
      <div class="col-4 text-center">
        <div class="kiosk-card">
          <h2 class="kiosk-card--title">REEB Energy Benchmark<br /><small>Category: Office (Air-conditioned)</small></h2>
          <GaugeChart class="kiosk-pie kiosk-benchmark" :options="reebChartOptions" />
          <p class="kiosk-card--display font-size-h2">
            <i class="fa fa-circle text-success"></i> Best Practice: <strong>124 kWh/m<sup>2</sup></strong>
          </p>
          <p class="kiosk-card--display font-size-h2 border-bottom pb-5 mb-5">
            <i class="fa fa-circle text-warning"></i> Typical Practice: <strong>174 kWh/m<sup>2</sup></strong>
          </p>
          <p class="kiosk-card--display font-size-h1">
            This Building: <strong>{{ consumptionPerM2 }} kWh/m<sup>2</sup></strong>
          </p>
        </div>
      </div>
      <div class="col-4 text-center">
        <div class="kiosk-card">
          <h2 class="kiosk-card--title">LETI Energy Benchmark</h2>
          <p class="kiosk-card--display">TBC</p>
        </div>
      </div>
      <div class="col-4 text-center">
        <div class="kiosk-card">
          <h2 class="kiosk-card--title">Etainabl Energy Benchmark</h2>
          <p class="kiosk-card--display">TBC</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import GaugeChart from '@/components/kiosk/GaugeChart';

export default {
  name: 'KioskBenchmark',
  components: {
    GaugeChart
  },
  computed: {
    ...mapGetters({
      asset: 'kiosk/asset',
      assetConsumption: 'kiosk/assetConsumption'
    }),
    reebChartOptions() {
      return {
        yAxis: {
          min: 0,
          max: 320,
          plotBands: [
            {
              from: 0,
              to: 124,
              color: '#82b54b',
              innerRadius: '95%'
            },
            {
              from: 124,
              to: 174,
              color: '#ffb119',
              innerRadius: '95%'
            },
            {
              from: 174,
              to: 320,
              color: '#e04f1a',
              innerRadius: '95%'
            }
          ]
        },
        series: [
          {
            name: 'Benchmark',
            data: [parseInt(this.consumptionPerM2)],
            dataLabels: {
              enabled: false
            },
            radius: '95%'
          }
        ],
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true,
              distance: 10,
              style: {
                fontFamily: 'Inter',
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 'normal',
                textOutline: 'none'
              }
            }
          }
        }
      };
    },
    consumptionPerM2() {
      let floorArea = this.asset.floorArea;

      if (this.asset.floorAreaUnit === 'imperial') {
        floorArea = this.asset.floorArea / 10.764;
      }

      return (this.assetConsumption.invoice.totalConsumption / floorArea).toFixed(0);
    }
  }
};
</script>
<style lang="scss">
.kiosk-pie {
  height: 320px;
}
</style>
